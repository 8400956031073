.margin-bottom {
    margin-bottom:15px !important;
}

.fix-width {
    width:1000px !important;
}

.fix-width-small {
    width:450px !important;
}

.sticky {
    top: 0;
    position: sticky;
}

a {
    color: #000;
}
a:hover {
    color: #00F
}
